import React, { useState, useRef, createRef, useEffect } from "react";
import styled from "@emotion/styled";
import { gsap } from "gsap";
import { useMediaQuery } from "../../hooks";

import HeaderLogo from "./HeaderLogo";
import HeaderNav from "./HeaderNav";
import HeaderBurger from "./HeaderBurger";

const StyledHeader = styled.header`
  position: fixed;
  width: 100%;
  height: 90px;
  overflow: hidden;
  z-index: 99;
  /* @media screen and (max-width: ${(props) =>
    props.theme.responsive.medium}) {
    background: ${(props) => props.theme.colors.white};
    box-shadow: 0 5px 30px 0 rgba(0, 0, 0, 0.2);
  } */
  .header__wrapper {
    position: relative;
    /* padding: 0 24px; */
    padding: 0 24px;
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;

    @media screen and (min-width: ${(props) => props.theme.responsive.medium}) {
      grid-template-columns: 26% 74%;
      padding: 0 24px;
    }

    @media screen and (min-width: ${(props) => props.theme.responsive.large}) {
      padding: 0 48px;
    }
  }
`;

const Header = ({ items }) => {
  // console.log("items", items);
  //Setup state to determine if menu is open or not
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const isSmallScreen = useMediaQuery("(max-width: 989px)");

  //Setup the Nav ref
  const navRef = useRef();

  // Loop through the items and create a state of navItems with refs to use for our animation
  const [navItems] = useState(
    items.map((item) => {
      return {
        ...item,
        ref: createRef(),
      };
    })
  );

  //Setup a timeline to use
  const [menuTL] = useState(
    gsap.timeline({
      paused: true,
      defaults: { duration: 0.6, ease: "expo.out" },
    })
  );

  useEffect(() => {
    const itemsRefs = navItems.map((item) => item.ref.current);
    // console.log(navRef.current);

    if (isSmallScreen) {
      menuTL
        .fromTo(
          navRef.current,
          { opacity: 0, height: "80px" },
          { opacity: 1, height: "100vh" }
        )
        .reverse()
        .fromTo(
          itemsRefs,
          { autoAlpha: 0, y: 48 },
          { autoAlpha: 1, y: 0, stagger: 0.1 },
          "-=0.4"
        );
    } else {
      menuTL.seek(0).clear().pause();
      gsap.set([navRef.current, itemsRefs], { clearProps: "all" });
    }
  }, [isSmallScreen, menuTL, navItems]);

  //Run menuTL base on Menu State
  useEffect(() => {
    menuTL.reversed(!isMenuOpen);
  }, [isMenuOpen, menuTL]);

  //onClick function to set state of menu
  const toggleNav = () => {
    setIsMenuOpen(!isMenuOpen);
    document.documentElement.classList.toggle("contain");
  };

  const close = () => {
    setIsMenuOpen(!isMenuOpen);
    document.documentElement.classList.remove("contain");
  };
  const closeLogo = () => {
    setIsMenuOpen(false);
    document.documentElement.classList.remove("contain");
  };

  return (
    <>
      <StyledHeader>
        <div className="header__wrapper">
          <HeaderLogo closeLogo={closeLogo} />
          <HeaderNav
            items={navItems}
            isMenuOpen={isMenuOpen}
            ref={navRef}
            close={close}
          />
          <HeaderBurger toggleNav={toggleNav} isMenuOpen={isMenuOpen} />
        </div>
      </StyledHeader>
    </>
  );
};

export default Header;
