const theme = {
  useColorSchemeMediaQuery: true,
  colors: {
    white: "#ffffff",
    // lightGreen: "#acb7ae",
    lightGreen: "#A1B5AA",
    GreenOpacity: "#BFCBC3",
    darkGreen: "#5e7858",
    pink: "#e3cace",
    lightGrey: "#f3f2f1",
    warmGrey: "#454142",
    lightTaupe: "#ebe5e0",
    darkTaupe: "#908375",
    greyText: "#555555",

    black: "#26201e",
    text: "#292929",
    reverseText: "#ffffff",
    primary: "#313131",
    secondary: "#dfdedc",
    accent: "#C29967",
    muted: "#e7e6e1",
    code: "#e7e6e1",
  },
  fonts: {
    body: '"Inter var",-apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif',
    headings: "BV_Celine_Regular",
    headingsItalic: "BV_Celine_Italic",
  },
  fontWeights: {
    extraLight: 200,
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
  },
  fontSizes: {
    body: "1em",
    headingDesktop: "3em",
    headingMobile: "2em",
    subHeading: "0.8em",
    highlightDesktop: "28px",
    highlightMobile: "20px",
    wordDesktop: "6rem",
    wordMobile: "4rem",
    priceCard: "44px",
    bigText: "1.2em",
  },
  lineHeights: {
    body: "1.625em",
  },
  letterSpacings: {
    body: "0.2px",
  },
  sizes: {
    bigMaxWidth: "1600px",
    mediumMaxWidth: "1440px",
    maxWidth: "860px",
    strokeWidth: "1.4",
    borderRadius: "1rem",
    paddingTopContainer: "150px",
    paddingTopMobileContainer: "10px",
    paddingBottomContainer: "100px",
    paddingLeftAndRightContainer: "24px",
    paddingLeftAndRightMobileContainer: "12px",
    gap: "60px",
    mobileGap: "4px",
  },
  responsive: {
    small: "35em",
    medium: "50em",
    large: "80em",
  },
  animation: {
    easing: "cubic-bezier(0.5, 0.7, 0.4, 1)",
  },
};
export default theme;
